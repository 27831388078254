@import 'styles/import'; 

.nt-button {	
	position: relative;
	display: inline-block;
	
 	border: 1px solid $color_accent;
		
	transition-property: border;
	transition-timing-function: $easing;
	transition-duration: $transition_normal;
	
 	
	&, a, button { 
		cursor: pointer;
	
		color: $color_white;
	
		font-family: $title_font;
		font-size: inherit;
		font-weight: 600;
		
		letter-spacing: 1px;
		
		text-align: center;
		text-transform: uppercase;
		
		line-height: 1;
	}
	
	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		left: -1px;
		top: -1px;
		width: 100%;
		height: 100%;
		z-index: 1;
		
		transition-timing-function: $easing;
		transition-duration: $animation_fast;
		
		border: 1px solid mix($color_primary, $color_accent, 60%);
		
		pointer-events: none;
	}
	
	&:before {
		width: calc(100% - 2em);
		
		border-left: 0;
		border-right: 0;
		
		left: 50%;
		transform: translateX(-50%);
		
		transition-property: width;
	}
	
	&:after {
		height: calc(100% - 2em);
		
		border-top: 0;
 		border-bottom: 0;
		
		top: 50%;
		transform: translateY(-50%);
 		
		transition-property: height;
	}
	
	&:hover {
		&:before {
			width: 0;			
		}
		
		&:after {
			height: 0;			
		}
		
		.btn {
			
			background: rgba($color_accent,0.1);
		}
	}
	
	&:active {
		border-color: $color_primary_active;
	}
	
	.btn {
		display: block;
		background: rgba($color_accent,0);
		
		min-width: 100px;
		padding: 1.45em 4em;
		
		transition-property: background;
		transition-timing-function: $easing;
		transition-duration: 0.5s;
	}
}