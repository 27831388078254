@import 'styles/import'; 

.nt-loading {
	background: rgba($color_black,0.8);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	.wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		
		transform: translate(-50%,-50%);
	}
	
	.icon {	
		position: relative;
		display: block;
		width: 3em;
		height: 3em;
		
		border: 0.75em solid transparent;
		border-right-color: $color_primary;
		border-left-color: inherit;
		border-radius: 100%;
		
		margin: 1em auto;	
			
		animation: rotate $animation_slow infinite linear;
			
		&:before,&:after{
			content: '';
			
			background: currentcolor;
			position: absolute;
			width: 2em;
			height: 2em;
			left: 50%;
			bottom: 100%;
			
			margin: 0 -1em;		
			border-radius: 100%;
			
			transform-origin: center 165%;
			
			animation: bounce $animation_slow infinite $easing;
		}
		
		&:after{
			background: $color_primary;
			top: 100%;
			bottom: inherit;
			left: inherit;
			right: 50%;
			
			transform-origin: center -65%;
			
			animation-delay: -($animation_slow*0.5);
		}
	}
}

@include set_animations('rotate')
{
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}

@include set_animations('bounce')
{
	0%, 100%
	{
		transform: scale(0.2);
	} 50% {
		transform: scale(1.0);
	}
}