// Hide
%hide{
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
}

// Show
%show{
	pointer-events: all;
	visibility: visible;
	opacity: 1;
}

// Text
%text_shadow {
	text-shadow: 3px 3px 5px rgba($color_black,0.4);
}

%headlines {
	font-family: $title_font;
	font-weight: $font_medium;
	
	text-transform: uppercase;
	
	margin-bottom: $margin_s;
	
	@extend %text_shadow;
	
	strong {
		font-weight: $font_ex_bold;
		margin-left: -0.09433962264em;
	}
}