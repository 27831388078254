@import 'styles/import'; 

.effect-dots {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	pointer-events: all;
}