/*/////////
*/// Colors

// Baseline
$color_black: #000000; // Black
$color_white: #FFFFFF; // White
$color_grey: #777777; // Grey

// States/status
$color_v: #389AD9; // Valid
$color_e: #C70E00; // Error
$color_w: #F29C2A; // Warning

// Colors
$color_dark_purple: #481D53;
$color_purple: #8705A9;
$color_pink: #B971CA;
$color_yellow: #CAF10A;

$color_primary: $color_dark_purple; // Primary color
$color_primary_active: #EFFF04;
$color_accent: #77D119; // Accent color

// Background
$background_dark: $color_black; // Dark
$background_light: $color_white; // Light

// Text
$text_dark: #737373; // Dark
$text_light: #FFFEFF; // Light

// Links
$link_d: $color_primary; // Default
$link_h: $color_primary; // Hover
$link_a: inherit; // Active

/*////////
*/// Sizes

// Site
$min_width: 300px; // Min site width
$max_width: 1600px; // Max site width

// Breaks
$break_phone: 299px;
$break_tablet_portait: 600px;
$break_tablet_landscape: 900px;
$break_desktop: 1200px;
$break_desktop_large: 1800px;

// Margins
$margin_s: 20px; // Small margin
$margin_d: 40px; // Default margin
$margin_l: 80px; // Large margin

// Padding
$padding_s: 15px; // Small padding
$padding_d: 30px; // Default padding
$padding_l: 60px; // Large padding

/*/////////////////////
*/// Transistion Speeds

$transition_slow: 1.2s;
$transition_normal: 0.3s;
$transition_fast: 0.15s;

/*///////////////////
*/// Animation Speeds

$animation_slow: 1.2s;
$animation_normal: 0.9s;
$animation_fast: 0.6s;

/*/////////
*/// Easing

$easing: ease-in-out;