@import 'styles/import'; 

.header {
	position: fixed;
	left: 0;
	top: 0;
	
	pointer-events: none;
	padding: $padding_d;
	
	z-index: 9;
	
	> * {
		pointer-events: all
	}
	
	.logo-menu {
		position: relative;
		
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		
		transition-property: opacity, color;
		transition-timing-function: $easing;
		transition-duration: $animation_fast, $transition_normal;
		
		&:before {
			content: "";
			
			display: block;
			position: absolute;
			left: 1.5rem;
			top: 3px;
			width: calc(100% - 3rem);
			height: calc(100% - 6px);
			
			border-radius: 1.5rem;
			
			box-sizing: content-box;
			z-index: -1;
			
			pointer-events: all;
			
			backdrop-filter: blur(0.4rem);
		}
	
		&.closed {
			opacity: 0.2;
			transition-delay: $transition_normal, 0s;
			transition-duration: $transition_normal;
		}
		
		&.open {
			opacity: 1;

			.logo-text {
				transition-delay: 0s;
				transform: translateY(-200%);
				opacity: 0;
			}
		}
		
		> nav {
			overflow: hidden;
		
			transition-property: width;
			transition-delay: $transition_normal;
			transition-timing-function: $easing;
			transition-duration: 0.1s;		
		}
	
		&.dark {
			color: $color_dark_purple;
		}
	}
	
	.nt-chevron {
		font-size: 1.6666666667rem;
		
		transition-property: color;
		transition-timing-function: $easing;
		transition-duration: $transition_normal;
	}
	
	.links {
		list-style: none;
		margin: -1px 0 0;
	}
	
	li {
		display: inline-block;
		line-height: 1;
	}
	
	.link {
		display: block;
		position: relative;
		cursor: pointer;
		
		color: inherit;
		font-family: $content_font;
		font-size: 1.6rem;
		font-weight: $font_medium;
		
		padding: 3px $padding_s 5px;
		
		transition-property: color;
		transition-timing-function: $easing;
		transition-duration: $transition_normal;
		
		&:before {
			content: "";
			
			background: currentcolor;
			
			position: absolute;
			left: $padding_s;
			bottom: 0;
			
			width: calc(100% - #{$padding_s * 2});
			height: 1px;
			
			opacity: 0;
			
			transition-property: opacity, background;
			transition-delay: $transition_normal;
			transition-timing-function: $easing;
		}
		
		&:hover {
			color: $color_accent;
		}
		
		&.active, &:active {
			color: inherit;
			
			&:before {
				background: $color_accent;
				opacity: 1;
			}
		}
	}

	.logo-text {
		position: absolute;
		left: 0;
		top: 100%;

		font-family: $frank;
		font-weight: 200;
		width: 6.6666rem;

		pointer-events: none;

		transition-property: transform, opacity;
		transition-timing-function: $easing;
		transition-delay: $transition_normal;
		transition-duration: $transition_normal;
	}
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.header {
		.logo-menu {
			&:before {
				opacity: 0;
				background: rgba($color_black,0.5);
				box-shadow: 0px 0px 1em rgba($color_black,0.2);	
				
				transition-property: all;
				transition-delay: $transition_normal;
				transition-timing-function: $easing;  
			}
			
			&.dark {
				&:before {
					background: rgba($color_white,0.5);
					box-shadow: 0px 0px 1em rgba($color_white,0.2);
				}
			}
			
			&.open {
				&:before {
					opacity: 1;
				}
			}
		}
	}
}

.mobile-logo-menu {
	transition-property: opacity, color, transform;
	transition-timing-function: $easing;
	transition-duration: $transition_normal;
	
	width: calc(100vw - #{$padding_d * 2});
	
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	
	align-items: center;
	
	opacity: 0.25;
		
	&.dark {
		color: $color_dark_purple;
	}
	
	&.close {
		&.fade {
			transform: translateY(-200px);
			opacity: 0;
		}
	}

	&.open {
		color: $color_white;
		opacity: 1;
	}

	a {
		position: relative;
	}

	.logo-text {
		position: absolute;
		left: 0;
		top: 100%;

		font-family: $frank;
		font-weight: 200;
		width: 6.6666rem;
	}

	.hamburger {		
		display: inline-block;
		font-size: 2rem;
		margin-left: auto;
		
		i {
			position: relative;
			display: inline-table;
			
			width: 2em;
			height: 4px;
			
			border-top: 2px solid;
			border-bottom: 2px solid;
			
			margin: 0.6em auto;
			float: right;
			
			transition: border $transition_normal $easing, transform $transition_normal $easing;
			
			&:before,&:after {
				content: "";
				
				display: block;
				position: absolute;
				width: 100%;
				left: 0;
				
				margin-left: -1em;
				
				transform: translateX(50%);
				
				transition-property: border, padding, margin, transform;
				transition-timing-function: $easing;
				transition-duration: $transition_normal;
				
				box-sizing: border-box;
			}
			
			&:before {
				top: 0;
				padding-bottom: 0.6em;
				border-bottom: 4px solid;
			}
			
			&:after {
				bottom: 0;
				padding-top: 0.6em;
				border-top: 4px solid;
			}
		}
		
		&:active, &.open {
			i {
				border-color: transparent;
				transform: scale(0.5);
				
				&:before {
					padding-bottom: 0;
					margin-top: -3px;
					transform: translateX(50%) rotate(45deg) scale(1.5);
				}
				&:after {
					padding-top: 0;
					transform: translateX(50%) rotate(-45deg) scale(1.5);
				}
			}
		}
	}
}

.mobile-nav-menu {
	background: rgba($color_black,0.9);
	
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	
	z-index: 8;
	
	backdrop-filter: blur(0.4rem);
	
	display: flex;
	flex-direction: column;
	
	padding: $padding_d * 2 0 0;
	border-top: 5rem solid transparent;
	
	transition-property: opacity;
	transition-timing-function: $easing;
	transition-duration: $transition_normal;
	
	opacity: 0;
	pointer-events: none;
	
	&.open {
		opacity: 1;
		pointer-events: all;
	}
	
	ul {	
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-grow: 1;
		
		text-align: center;
		
		border-top: 1px solid rgba($color_black,0.5);
		border-bottom: 1px solid rgba($color_white,0.1);
	}
	
	li {
		position: relative;
		width: 100%;
		flex-grow: 1;
		
		border-top: 1px solid rgba($color_white,0.1);
		border-bottom: 1px solid rgba($color_black,0.5);
	}
	
	.link {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		
		height: 100%;
		
		align-content: center;
		justify-content: center;
	
		cursor: pointer;
		
		color: inherit;
		font-family: $content_font;
		font-size: $fs_l;
		font-weight: $font_medium;
		
		padding: $padding_d;
		
		transition-property: color;
		transition-timing-function: $easing;
		transition-duration: $transition_normal;
		
		&:before, &:after {
			content: "";
			
			background: currentcolor;
			
			position: absolute;
			top: 0;
			
			width: 3px;
			height: 100%;
			
			opacity: 0;
			
			transition-property: opacity, background;
			transition-delay: $transition_normal;
			transition-timing-function: $easing;
		}
		
		&:before {
			left: 0;
		}
		
		&:after {
			right: 0;
		}
		
		&:hover {
			color: $color_accent;
		}
		
		&.active, &:active {
			color: inherit;
			
			&:before, &:after {
				background: $color_accent;
				opacity: 1;
			}
		}
	}
}
