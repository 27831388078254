@import 'styles/import'; 

.friend-logos {
	align-items: center;	
}

.friend-logo {
	display: block;
	
	width: auto;
	max-width: 100%;
	height: 90px;
	
	margin: 0 auto;
	
	object-fit: contain;
	
	&.small {	
		height: 45px;
	}
}

@media only screen and (min-width: $break_tablet_landscape)
{
	.friend-logo {
		.pardot {
			margin-bottom: -16px;
		}
	}
}

@media only screen and (min-width: $break_desktop)
{
	.friend-logo {
		&.pardot {
			margin-bottom: 0;
		}
		
		&.hubspot {
			height: 40px;
		}
	}
}