@import 'styles/import'; 

$transition_bar: 0.4s;

.contact-form {
	background: rgba($color_black,0.7);
	position: relative;
	padding: $padding_d;
	
	font-size: $fs_l;
	
	&.success, &.error {
		.nt-grid {
			pointer-events: none;
			opacity: 0;
		}
	}
	
	&.loading {
		.nt-grid {
			pointer-events: none;
			opacity: 0.5;
		}
	}
	
	.nt-grid {
		transition-property: opacity;
		transition-timing-function: $easing;
		transition-duration: $transition_normal;
	}
	
	.float-field {
		position: relative;
		
		color: rgba($color_white,0.5);
		font-family: $content_font;
		font-size: $fs_n;
		line-height: 1;
		
		overflow: hidden;
		
		&:before, &:after {
			content: "";
			
			background: $color_yellow;
			position: absolute;
			right: 100%;
			bottom: 0;
			
			width: 200%;
			height: 1px;
			
			
			transition-property: right;
			transition-timing-function: $easing;
			transition-duration: $transition_bar;
		}
		
		&:after {
			background: $color_accent;
			z-index: 1;
		}
		
		input, textarea {			
			background: none;
			display: block;
			width: 100%;
			height: auto;
			
			color: inherit;
			font-family: $content_font;
			font-size: inherit;
			line-height: 1;
			
			padding: $padding_s;
		}
		
		textarea {
			resize: none;
		}
		
		.label {
			position: absolute;
			left: $padding_s;
			top: 1px;
			
			padding: $padding_s 0;
			
			transform-origin: top left;
			
			transition-property: transform, color;
			transition-timing-function: $easing;
			transition-duration: $transition_normal;
			
			pointer-events: none;
		}
		
		.field {
			border-bottom: 1px solid rgba($color_white,0.5);
			
			transition-property: border;
			transition-timing-function: $easing;
			transition-duration: $transition_normal;
		}
		
		&.has-value, &.focused {
			color: $color_white;	
			
			.label {
				color: $color_accent;
				transform: scale(0.75) translateY(-#{$padding_s + 5px});
			}
		}
		
		
		&.focused {			
			.field {
				border-color: $color_white;
			}
		}
		
		&:hover, &.focused {
			color: $color_white;
			
			&:before, &:after {
				right: 0;
			}
			
			&:after {
				transition-delay: $transition_bar;
			}
		}
		
		&.invalid {
			.label {
				color: $color_e;
			}	
			
			.field {
				border-color: $color_e;
			}
		}
	}
	
	.nt-loading {
		background: none;
	}
	
	.success-message, .error-message {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		p {
			max-width: 400px;
			color: $color_accent;
			text-align: center;
			margin: $padding_s auto;
			padding: 0 $padding_d;
		}
	}
	
	.error-message {
		p {
			color: $color_e;
		}
	}
}

@media only screen and (min-width: $break_tablet_landscape) 
{
	.contact-form {
		.float-field{
			font-size: $fs_m;		
			
			&.has-value, &.focused {
				.label {
					transform: scale(0.5833333333) translateY(-#{$padding_s + 5px});
				}
			}	
		}
	}
}


@media only screen and (min-width: 1440px) 
{
	.contact-form {
		.float-field{
			font-size: $fs_l;	
		}
	}
}

.grecaptcha-badge {
	display: none;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition-duration: 99999999s;
}