@import 'styles/import'; 

.nt-section {
	position: relative;
	z-index: 1;
	
	overflow: hidden;
	
	&.light {
		background: $color_white;
		color: $text_dark;
	}
	
	> .content {
		position: relative;
		z-index: 2;
		
		width: 100%;
		max-width: $break_desktop_large;
		padding: $padding_d;
		margin: 0 auto;
	}
	
	> .bkg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		
		overflow: hidden;
		pointer-events: none;
		
		img, svg, canvas, iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
			object-fit: cover;
		}
	}
}

.section-divider-border-top, .section-divider-border-bottom {
	background: $color_black;
	
	display: block;
	width: 100%;
	height: 10px;	
}
	
.section-divider-border-top {	
	border-top: 1px solid $color_grey;
}

.section-divider-border-bottom {		
	border-bottom: 1px solid $color_grey;
}


@media only screen and (min-width: $break_tablet_landscape) 
{
	.nt-section {	
		> .content {
			padding: $padding_l + $padding_d $padding_l;
		}
	}
}


@media only screen and (min-width: $break_desktop) 
{
	.nt-section {	
		> .content {
			padding: $padding_l + $padding_d $padding_l * 2;
		}
	}
}