@import 'styles/import'; 

$cols: 24;
	
.nt-grid {
	display: flex;
	flex-direction: row;	
	flex-wrap: wrap;
	
	&.gap-s {
		margin: -$margin_s #{-$margin_s/2} 0;
		
		> .item {
			padding: $margin_s $margin_s/2 0;
		}
	}
	
	&.gap-d {
		margin: -$margin_d #{-$margin_d/2} 0;
		
		> .item {
			padding: $margin_d $margin_d/2 0;
		}
	}
	
	&.gap-l {
		margin: -$margin_l #{-$margin_l/2} 0;
		
		> .item {
			padding: $margin_l $margin_l/2 0;
		}
	}
	
	&.gap-justify {
		justify-content: space-between;
	}
	
	> .clear-item {
		width: 100%;
		order: 2;
		padding: 0;
	}
	
	> .item {
		position: relative;
		order: 2;
		
		&.right{ margin-left: auto }
		&.left{ margin-right: auto }
		
		$not: '';
		$n: 1;
		@while $n <= $cols
		{	
			$w: percentage($n/$cols);
			
			&.span-#{$n}{width: $w}
			&.offset-left-#{$n}{ margin-left: $w }
			&.offset-right-#{$n}{ margin-right: $w }
			
			$n: $n + 1;
		}
		
		&.span-auto{
			width: auto;
		}
		
		&.full{
			width: 100%;
		}
		
		&.half{
			width: 50%;
		}
		
		&.grow {
			flex-grow: 1;
		}
		
		&.no-grow{
			flex-grow: 0;
		}
	}
}

@mixin flex_columns($size,$prefix)
{
	@media only screen and (min-width: $size)
	{
		.nt-grid {
			&.#{$prefix}-gap-s {
				margin: -$margin_s #{-$margin_s/2} 0;
				
				> .item {
					padding: $margin_s $margin_s/2 0;
				}
			}
			
			&.#{$prefix}-gap-d {
				margin: -$margin_d #{-$margin_d/2} 0;
				
				> .item {
					padding: $margin_d $margin_d/2 0;
				}
			}
			
			&.#{$prefix}-gap-l {
				margin: -$margin_l #{-$margin_l/2} 0;
				
				> .item {
					padding: $margin_l $margin_l/2 0;
				}
			}
			
			> .item {
				&.#{$prefix}-right{ margin-left: auto }
				&.#{$prefix}-left{ margin-right: auto }
				
				$not: '';
				$n: 1;
				@while $n <= $cols
				{	
					$w: percentage($n/$cols);
					
					&.#{$prefix}-span-#{$n}{width: $w}
					&.#{$prefix}-offset-left-#{$n}{ margin-left: $w }
					&.#{$prefix}-offset-right-#{$n}{ margin-right: $w }
					
					$n: $n + 1;
				}				
		
				&.#{$prefix}-span-auto{
					width: auto;
				}
				
				&.#{$prefix}-full {
					width: 100%;
				}
				
				&.#{$prefix}-half {
					width: 50%;
				}
				
				&.#{$prefix}-grow {
					flex-grow: 1;
				}
				
				&.#{$prefix}-no-grow {
					flex-grow: 0;
				}
			}
		}
	}
}

@include flex_columns($break_phone,'xs');
@include flex_columns($break_tablet_portait,'sm');
@include flex_columns($break_tablet_landscape,'md');
@include flex_columns($break_desktop,'lg');
@include flex_columns($break_desktop_large,'xl');

