@import 'styles/import'; 

.about-effect {
	background: linear-gradient(45deg, $color_dark_purple, #280A2F, $color_purple, #331B47);
	background-size: 400% 400%;
	animation: about_background 30s ease infinite;
	
	box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.39) inset, 0px -6px 10px 0px rgba(0,0,0,0.39) inset;

	&:before, &:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	
	&:before {
		background: linear-gradient(45deg, rgba($color_dark_purple,0.5), rgba($color_black,0));
		background-size: 300% 300%;
		animation: about_background 45s ease infinite;
	}
	
	&:after {
		background: linear-gradient(0deg, rgba($color_black,0.5), rgba($color_black,0));
		background-size: 100% 100%;
		
		z-index: 1;
	}
}

@keyframes about_background {
	0% { background-position: 0% 50% }
	50% { background-position: 100% 50% }
	100% { background-position: 0% 50% }
};

.watermark {
	position: absolute;
	right: -30vw;
	bottom: -30vw;
	opacity: 0.05;
	
	font-size: 20vw;
}