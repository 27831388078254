@import 'styles/import'; 

.nt-chevron {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 3em;
	
	&:before, &:after {
		background: currentcolor;
		
		position: absolute;
		top: 14.814814815%;
		
		content: "";
		display: block;
		
		width: 36.11111111%;
		height: 70.37037037%;
	}
	
	&.left {
		&:before, &:after {
			left: 0;
			transform-origin: left center;
		}
	
		&:before {
			transform: rotate(45deg) translateY(-50%);
		}
		&:after {
			transform: rotate(-45deg) translateY(50%);
		}
	}
	
	&.right {
		&:before, &:after {
			right: 0;
			transform-origin: right center;
		}
	
		&:before {
			transform: rotate(-45deg) translateY(-50%);
		}
		&:after {
			transform: rotate(45deg) translateY(50%);
		}
	}
}