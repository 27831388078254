@import 'styles/import'; 

.fade-effect {
	opacity: 0;
	
	&.do-fade {
		animation: fade_effect_animation $animation_fast ease;
		animation-fill-mode: both;
	}
}
	
@keyframes fade_effect_animation {
	0% { opacity: 0; transform: translateY($padding_l); }
	100% { opacity: 1; transform: none; }
};