@import 'styles/import'; 

.section-title {
	margin-bottom: $margin_d;
	
	.sub-title {
		display: block;
		
		font-size: $fs_n;
		color: $color_pink;
		
		margin-bottom: $padding_s;
	}
	
	.title {
		display: inline-block;
		
		font-size: $fs_xl;
		font-weight: 500;
		letter-spacing: -0.03333333333em;
		color: $color_dark_purple;
		
		padding-bottom: $padding_s;
		border-bottom: 10px solid $color_purple;
	}
	
	&.light {	
		.title {
			color: $color_white;
			border-color: $color_white;
		}
	}
}

@media only screen and (min-width: $break_tablet_landscape) 
{
	.section-title {
		.sub-title {
			font-size: $fs_l;			
		}
		
		.title {
			font-size: $fs_xxl;
		}
	}
}