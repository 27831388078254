@import 'styles/import'; 

.nt-loading-screen {
	background: $color_black;
	
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
		
	z-index: 100;
	
	transition-property: opacity;
	transition-timing-function: $easing;
	transition-duration: $transition_normal;
	
	&.loaded {
		opacity: 0;
		pointer-events: none;
	}
}