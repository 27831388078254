@import 'styles/import'; 

.section-content {
	width: 100%;
	height: 100%;
	
	@include golden_ration();
	
	h1,h2,h3,h4,h5,h6{
		hyphens: manual;
	}
	
	p,li{
		display: inline-block;
		width: 100%;
		hyphens: manual;
	}
	
	ul, ol {
		display: inline-block;
		width: 100%;
	}
	
	ul{
		> li{list-style-type: circle}
		
		&.shuriken {
			column-width: 200px;
			column-gap: $padding_d;
			
			> li {
				list-style-type: none;
				margin-left: 1.5em;
				
				&:before {
					content: "";
					
					display: inline-block;
					
					width: 1em;
					height: 1em;
					
					margin: -2px 0.5em 0 -1.5em;
					
					vertical-align: middle;
					
				    background: url("/media/shuriken.svg") no-repeat;
				}
			}
		}
		
		ul{
			> li{list-style-type: square}
		}
	}
	
	ol{
		> li{list-style-type: decimal-leading-zero}
		
		> ol{
			> li{list-style-type: decimal}
		}
	}
	
	li{
		display: list-item;
		list-style-position: inside;
		
		> ul, > ol{
			margin-top: 0;
			padding-left: 1.5em;
		}
	}
	
	.purple {color: $color_purple}
}