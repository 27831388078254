/// https://css-tricks.com/snippets/sass/str-replace-function/
/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string

@function str-replace($string, $search, $replace: '')
{
	$index: str-index($string, $search);
	
	@if $index
	{
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	
	@return $string;
}

/*////////////////////////
*/// CSS Animations Mixins

@mixin set_animations($class) // Mixin for animations
{
	.#{$class}{
		-webkit-animation-name: #{$class};
		animation-name: #{$class};
	}
	
	@-webkit-keyframes #{$class}
	{
	    @content;
	}
	@keyframes #{$class}
	{
	    @content;
	}
}

//!/////
// Strip
/// @var {String|Number}

@function _strip($var)
{
	@if type-of($var) == 'number' and not unitless($var)
	{
		@return $var / ($var * 0 + 1);
	}
	
	@return $var;
}


//!///////////
// Square Root
/// @num {Number}

@function _sqrt($num)
{
	$num: _strip($num) * 16;
	$x0: 1;
	$x1: $x0;
	
	@for $i from 1 through 10
	{
		$x1: $x0 - ($x0 * $x0 - abs($num)) / (2 * $x0);
		$x0: $x1;
	}
	
	@return $x1;
}

//!////////////
// Golden Ratio
/// @$width {String|px} - Width to calc the golden ratio

@mixin golden_ration($width: 100px)
{
	$ratio: 2.61803398874989;
	$square: _sqrt($width);
	$size: round($square / $ratio * 0.98px) + 1px;
	$line: round($square * 0.798px) + 1px;
	
	font-size: $size;
	line-height: $line;
	
	margin-top: -$line;
	
	pointer-events: none;
	
	> * { pointer-events: all }
	
	h1 {
		font-size: ($size * 4.236);
		line-height: ($line * 4.236);
	}
	
	h2 {
		font-size: ($size * 2.618);
		line-height: ($line * 2.618);
	}
	
	h3 {
		font-size: ($size * 1.618);
		line-height: ($line * 1.618);
	}
	
	p,ul,ol,h1,h2,h3,h4,h5,h6,img,blockquote,dl,pre,address,noscript {
		margin-top: $line;
	
		& + {
			h1,h2,h3,h4,h5,h6 {
				margin-top: $line*2.5;
			}
		}
	}
	
	.small {
		font-size: $size * 0.75;
		line-height: $line * 0.75;
	}
}