@import 'styles/import'; 

.section-sub-title {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: baseline;
		
	margin-bottom: $margin_s;
	
	font-size: $fs_xl;
	
	.title {
		font-size: 1em;
		color: $color_dark_purple;		
		flex-grow: 1;
		letter-spacing: -0.03333333333em;
	}
	
	.icon {
		display: block;
		width: auto;
		height: $fs_xl * 2;
	}
	
	&.small {
		padding-top: 0.3333333em;
		
		.title {
			font-size: 0.6em;
		}
	}
}