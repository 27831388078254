@import 'styles/import'; 

.footer {
	text-align: center;
	padding-bottom: $padding_d;
}

.social-link {
	position: relative;
	width: $margin_d;
	height: $margin_d;
	
	display: inline-block;
	text-align: center;
	font-size: $fs_m;
	line-height: $margin_d;
	
	transform-style: preserve-3d;
	perspective: 1000px;
	
	margin: 0 0 $padding_s;

	& + .social-link {
		margin-left: $padding_s;
	}
	
	.d, .h {
		display: block;
		width: 100%;
		height: 100%;
		
		@extend %text_shadow;
	}
	
	.d {
		background: $color_accent;
		position: relative;
		
		color: $color_white;
		
		transform-style: preserve-3d;
		transform-origin: center center #{-$margin_d / 2};
	
		transition-property: transform;
		transition-timing-function: $easing;
		transition-duration: $transition_normal;
	}
	
	.h {
		background: $color_yellow;
		position: absolute;
		left: 0;
		top: 100%;
		
		color: $color_black;
					
		transform-origin: 0 0;
		transform: rotateX(-90deg);
	}
	
				
	&:hover {
		.d {
			transform: rotateX(90deg);
		}
	}
}

@media only screen and (min-width: $break_tablet_portait) 
{
	.footer {
		text-align: left;
		padding-bottom: 0;
	}
}