@import 'styles/import'; 

.nt-html-logo {
	position: relative;
	display: block;
	width: 100%;
		
	line-height: 0;
	white-space: nowrap;
	
	.nt-autosize {
		display: inline-table;
	}
	
	.gradient {
		position: relative;
		display: inline-block;
		z-index: 2;
		
		mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 49.75%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 1) 80%);
	}
	
	.shadow {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		
		color: $color_black;
		
		filter: drop-shadow(0px 0.15em 0.15em rgba($color_black,0.5));
	}
	
	.registered {
		position: absolute;
		left: 50%;
		bottom: 0;
		
		font-family: sans-serif;
		font-size: 0.2em;
		line-height: 1;
		
		margin-left: 4em;
	}
	
	.text {
		.frank {
			margin-top: 0.5em;
		}
	}
	
	&.dark {		
		.shadow {			
			filter: drop-shadow(0px 0.15em 0.05em rgba($color_black,0.5));
		}
	}
}