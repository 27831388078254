/*///////////////
*/// Font Weights

$font_light: 300;
$font_normal: 400;
$font_medium: 500;
$font_bold: 700;
$font_ex_bold: 800;

/*/////////////
*/// Font Sizes

$fs_xs: 1rem; // Extra Small
$fs_s: 1.2rem; // Small
$fs_n: 1.6rem; // Normal
$fs_m: 2rem; // Medium
$fs_l: 2.4rem; // Large
$fs_xl: 3rem; // Extra Large
$fs_xxl: 6rem; // Extra Extra Large

/*///////////////
*/// Line Heights

$title_lh: 1; // Line height used in h1-h6
$content_lh: 1.45; // Line height used in the content areas

/*////////////////
*/// Font Families

$jura: 'Jura', Jura, sans-serif;
$exo: 'Exo', Exo, serif;
$frank: frank-new;

$sans: $jura, sans-serif;
$serif: $frank, serif;

$title_font: $serif; // Fonts used in h1-h6
$content_font: $sans; // Fonts used in the content areas
