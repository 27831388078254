@import 'styles/import'; 

.nt-hero {
	position: relative;
	z-index: 1;
	
	text-align: center;
	
	> .bkg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		
		overflow: hidden;
		pointer-events: none;
		
		img, svg, canvas, iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
			object-fit: cover;
		}
		
		.w-ui-container {
			pointer-events: none !important;
			opacity: 0 !important;
			visibility: hidden !important;
		}
	}
	
	> .wrapper {
		position: relative;
		z-index: 2;
		max-width: $max_width;
		min-height: 100vh;	
		margin: 0 auto;
		padding: $padding_d;
		border-top: 5rem solid transparent;
		
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	
	> .wrapper > .content {
		width: 100%;
		font-size: $fs_l;
		margin: -$padding_l #{-$padding_l/2} 0;
		
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		
		align-items: center;
		
		> .left, > .right {
			padding: $padding_l $padding_l/2 0;
			width: 100%;
		}
		
		> .left {
			order: 0;
		}
		
		> .right {
			order: 2;
		}
		
		img {
			display: block;
			width: 100%;
			height: auto
		}
		
		p {
			@extend %text_shadow;
			margin: $margin_s 0;
		}
		
		.headline {
			@extend %headlines;
			line-height: 1.1;
		}
		
		.nt-button {
			margin-top: $margin_s;
		}
	}
}

@media only screen and (min-width: $break_tablet_landscape) 
{
	.nt-hero {
		text-align: left;
		
		> .wrapper {
			padding: $padding_l $padding_d;
			border-top: none;
			
			> .content {
				> .left {
					width: 40%;
				}
				
				> .right {
					width: 60%;
				}
			}
		}
	}
}

@media only screen and (min-width: $break_desktop) 
{
	.nt-hero {
		> .wrapper {
			padding: $padding_l $padding_l * 2;
		}
	}
}