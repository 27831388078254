/*/////////////////////
*/// CSS Shared Classes

// Floats
.clr-float{
	clear: both;
	
	&:empty{
		width: 100%;
		height: 1px;
		
		margin-top: -1px;		
	}
}
.float-left{float: left}
.float-right{float: right}

// Text
.jura { font-family: $jura }
.exo { font-family: $exo }
.frank { font-family: $frank; font-weight: 200; }

.text-ctr{text-align: center}
.text-left{text-align: left}
.text-right{text-align: right}

.fs-xs{font-size: $fs_xs};
.fs-s{font-size: $fs_s};
.fs-n{font-size: $fs_n};
.fs-m{font-size: $fs_m};
.fs-l{font-size: $fs_l};
.fs-xl{font-size: $fs_xl};
.fs-xxl{font-size: $fs_xxl};

.nobr {white-space: nowrap}


.hide-mobile {
	display: none;
}

@media only screen and (min-width: $break_tablet_landscape) 
{
	.hide-mobile {
		display: inherit;
	}
	
	.show-mobile {
		display: none;
	}
}