@import 'styles/import'; 

.client-logos {
	.item {
		align-self: center;
	}
}

.client-logo {
	align-self: center;
	
	img, svg {
		display: block;
		
		width: 100%;
		height: auto;
		max-height: 100px;
		
		margin: 0 auto;
		
		object-fit: contain;
	}
}
